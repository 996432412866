import Accordion from "./Accordion"
import Accordion50 from "./Accordion50"
import AddressCollection from "./AddressCollection"
import ArticlesDistributor from "./ArticlesDistributor"
import AudiovisualDistributor from "./AudiovisualDistributor"
import BasicContent from "./BasicContent"
import BasicWysiwyg from "./BasicWysiwyg"
import BlogDistributor from "./BlogDistributor"
import CTACollection from "./CTACollection"
import CardCollection from "./CardCollection"
import ChartCollection from "./ChartCollection"
import Countdown from "./Countdown"
import CypherCollection from "./CypherCollection"
import ProgramsPresentationAuto from "./ProgramsPresentationAuto"
import DocumentDownload from "./DocumentDownload"
import DownloadCollection from "./DownloadCollection"
import DownloadForm from "./DownloadForm"
import EpicAwardsDistributor from "./EpicAwardsDistributor"
import EventsDistributor from "./EventsDistributor"
import FeaturedAudiovisual from "./FeaturedAudiovisual"
import FeaturedBluePanel from "./FeaturedBluePanel"
import FeaturedBlock from "./FeaturedBlock"
import FeaturedProgram from "./FeaturedProgram"
import FeaturedTextBluePanels from "./FeaturedTextBluePanels"
import FeedbackForm from "./FeedbackForm"
import FloatingCTA from "./FloatingCTA"
import FloatingCTALightbox from "./FloatingCTALightbox"
import FloatingCTAMail from "./FloatingCTAMail"
import FloatingCTAVideo from "./FloatingCTAVideo"
import Footer from "./Footer"
import FooterSection from "./FooterSection"
import FullWidthForm from "./FullWidthForm"
import Header from "./Header"
import HeaderSection from "./HeaderSection"
import HeroCarousel from "./HeroCarousel"
import HeroClaim from "./HeroClaim"
import HeroImage from "./HeroImage"
import HeroLanding from "./HeroLanding"
import HeroMosaic from "./HeroMosaic"
import HeroProgram from "./HeroProgram"
import HeroProgramVideo from "./HeroProgramVideo"
import HeroQuote from "./HeroQuote"
import HeroSidePanel from "./HeroSidePanel"
import HeroSlider from "./HeroSlider"
import HeroSpecialization from "./HeroSpecialization"
import HeroText from "./HeroText"
import HeroVideo from "./HeroVideo"
import HorizontalTabs from "./HorizontalTabs"
import Iframe from "./Iframe"
import IframeQuiz from "./IframeQuiz"
import ImageGallery from "./ImageGallery"
import InformationStructure from "./InformationStructure"
import InformativeHeader from "./InformativeHeader"
import Intro from "./Intro"
import IntroForm from "./IntroForm"
import IntroTextCard from "./IntroTextCard"
import ListCollapsed from "./ListCollapsed"
import LoadScript from "./LoadScript"
import LocationMap from "./LocationMap"
import LogoCollection from "./LogoCollection"
import MasonryCollection from "./MasonryCollection"
import Milestones from "./Milestones"
import MultiPage from "./MultiPage"
import NewsDistributor from "./NewsDistributor"
import PersonDistributor from "./PersonDistributor"
import PopUp from "./PopUp"
import ProfileCollection from "./ProfileCollection"
import Principles from "./Principles"
import ProgramDistributor from "./ProgramDistributor"
import ProgramFinder from "./ProgramFinder"
import ProgramQuickFacts from "./ProgramQuickFacts"
import ProgramsPresentation from "./ProgramsPresentation"
import ProgramTabs from "./ProgramTabs"
import ProjectDistributor from "./ProjectDistributor"
import PublicationDistributor from "./PublicationDistributor"
import RegistrationWall from "./RegistrationWall"
import RequestInfoWithModal from "./RequestInfoWithModal"
import RequestInfo from "./RequestInfo"
import RequestInfoCard from "./RequestInfoCard"
import RequestInfoCollection from "./RequestInfoCollection"
import ScholarshipsDistributor from "./ScholarshipsDistributor"
import SchoolFooter from "./SchoolFooter"
import ScrollIndicator from "./ScrollIndicator"
import SearchModule from "./SearchModule"
import Section from "./Section"
import SimpleFloatingCTA from "./SimpleFloatingCTA"
import Sitemap from "./Sitemap"
import Slider from "./Slider"
import SliderPagination from "./SliderPagination"
import SocialMedia from "./SocialMedia"
import SocialShare from "./SocialShare"
import Spacer from "./Spacer"
import SpecializationDistributor from "./SpecializationDistributor"
import StoriesDistributor from "./StoriesDistributor"
import StepsCollection from "./StepsCollection"
import StudentPath from "./StudentPath"
import StudyPlan from "./StudyPlan"
import GeneralInfoDistributor from "./GeneralInfoDistributor"
import InsightsDistributor from "./InsightsDistributor"
import Table from "./Table"
import TagDistributor from "./TagDistributor"
import TypographicVideo from "./TypographicVideo"
import Testimonial from "./Testimonial"
import Timeline from "./Timeline"
import VerticalSlider from "./VerticalSlider"
import VerticalTabs from "./VerticalTabs"
import VideoCollection from "./VideoCollection"
import VideoGallery from "./VideoGallery"
import VideoPlaylist from "./VideoPlaylist"
import WebinarCollection from "./WebinarCollection"
import Wysiwyg from "./Wysiwyg"

// EXPORT
export default {
  Accordion,
  Accordion50,
  AddressCollection,
  ArticlesDistributor,
  AudiovisualDistributor,
  BasicContent,
  BasicWysiwyg,
  BlogDistributor,
  CTACollection,
  CardCollection,
  ChartCollection,
  Countdown,
  CypherCollection,
  ProgramsPresentationAuto,
  DocumentDownload,
  DownloadCollection,
  DownloadForm,
  EpicAwardsDistributor,
  EventsDistributor,
  FeaturedAudiovisual,
  FeaturedBluePanel,
  FeaturedBlock,
  FeaturedProgram,
  FeaturedTextBluePanels,
  FeedbackForm,
  FloatingCTA,
  FloatingCTALightbox,
  FloatingCTAMail,
  FloatingCTAVideo,
  Footer,
  FooterSection,
  FullWidthForm,
  GeneralInfoDistributor,
  Header,
  HeaderSection,
  HeroCarousel,
  HeroClaim,
  HeroImage,
  HeroLanding,
  HeroMosaic,
  HeroProgram,
  HeroProgramVideo,
  HeroSidePanel,
  HeroSlider,
  HeroSpecialization,
  HeroQuote,
  HeroText,
  HeroVideo,
  HorizontalTabs,
  Iframe,
  IframeQuiz,
  ImageGallery,
  InformationStructure,
  InformativeHeader,
  InsightsDistributor,
  Intro,
  IntroForm,
  IntroTextCard,
  ListCollapsed,
  LoadScript,
  LocationMap,
  LogoCollection,
  MasonryCollection,
  Milestones,
  MultiPage,
  NewsDistributor,
  PersonDistributor,
  PopUp,
  Principles,
  ProfileCollection,
  ProgramDistributor,
  ProgramFinder,
  ProgramQuickFacts,
  ProgramsPresentation,
  ProgramTabs,
  ProjectDistributor,
  PublicationDistributor,
  RegistrationWall,
  RequestInfoWithModal,
  RequestInfo,
  RequestInfoCard,
  RequestInfoCollection,
  ScholarshipsDistributor,
  SchoolFooter,
  ScrollIndicator,
  SearchModule,
  Section,
  SimpleFloatingCTA,
  Sitemap,
  Slider,
  SliderPagination,
  SocialMedia,
  SocialShare,
  Spacer,
  SpecializationDistributor,
  StoriesDistributor,
  StepsCollection,
  StudentPath,
  StudyPlan,
  Table,
  TagDistributor,
  TypographicVideo,
  Testimonial,
  Timeline,
  VerticalSlider,
  VerticalTabs,
  VideoCollection,
  VideoGallery,
  VideoPlaylist,
  WebinarCollection,
  Wysiwyg,
}
