import BasicTemplate from "./BasicTemplate"
import AcademicVideo from "./AcademicVideo"
import Error404 from "./Error404"
import SitemapTemplate from "./SitemapTemplate"
import ArticlesList from "./ArticlesList"
import AudiovisualList from "./AudiovisualList"
import AwardProposalForm from "./AwardProposalForm"
import EntrepreneurForm from "./EntrepreneurForm"
import EpicAwardDetail from "./EpicAwardDetail"
import EpicAwardList from "./EpicAwardList"
import EventDetail from "./EventDetail"
import EventList from "./EventList"
import GeneralInfoDetail from "./GeneralInfoDetail"
import GeneralInfoList from "./GeneralInfoList"
import Landing from "./Landing"
import NewsDetail from "./NewsDetail"
import NewsList from "./NewsList"
import PersonList from "./PersonList"
import ProgramComparator from "./ProgramComparator"
import ProgramDetail from "./ProgramDetail"
import ProgramsList from "./ProgramsList"
import ProjectDetail from "./ProjectDetail"
import ProjectList from "./ProjectList"
import PublicationDetail from "./PublicationDetail"
import PublicationsList from "./PublicationsList"
import RecruitForm from "./RecruitForm"
import ScholarshipsList from "./ScholarshipsList"
import SearchResults from "./SearchResults"
import SpecializationDetail from "./SpecializationDetail"
import StoriesList from "./StoriesList"
import StoryDetail from "./StoryDetail"
import TeacherDetail from "./TeacherDetail"
import TeachersList from "./TeachersList"

export default {
  BasicTemplate,
  AcademicVideo,
  Error404,
  SitemapTemplate,
  SearchResults,
  AwardProposalForm,
  EntrepreneurForm,
  RecruitForm,
  ArticlesList,
  AudiovisualList,
  EpicAwardDetail,
  EpicAwardList,
  EventDetail,
  EventList,
  GeneralInfoDetail,
  GeneralInfoList,
  Landing,
  NewsDetail,
  NewsList,
  PersonList,
  ProgramComparator,
  ProgramDetail,
  ProgramsList,
  ProjectDetail,
  ProjectList,
  PublicationDetail,
  PublicationsList,
  ScholarshipsList,
  SpecializationDetail,
  StoriesList,
  StoryDetail,
  TeacherDetail,
  TeachersList,
}
