import { getThemeOptions } from "@helpers/schemas"
import {
  StoriesDistributorD001,
  StoriesDistributorD0012x,
  StoriesDistributorD002,
} from "@thumbnails/Modules"
import { StoriesListLayout1, StoriesListLayout2 } from "@layouts"

export default {
  schemaType: "module",
  displayName: "Stories Distributor",
  component: "StoriesDistributor",
  category: "people",
  dataPacks: ["STORIES"],
  sectionList: {
    AcademicVideo: ["relatedContent"],
    ArticlesList: ["relatedContent"],
    AudiovisualList: ["relatedContent"],
    BasicTemplate: ["mainContent"],
    EpicAwardDetail: ["mainContent", "relatedContent"],
    EpicAwardList: ["relatedContent"],
    EventDetail: ["mainContent", "relatedContent"],
    EventList: ["relatedContent"],
    GeneralInfoDetail: ["gidMainContent", "gidRelatedContent"],
    GeneralInfoList: ["relatedContent"],
    Landing: ["mainContent"],
    NewsDetail: ["relatedContent"],
    NewsList: ["relatedContent"],
    ProgramDetail: ["relatedContent"],
    ProgramsList: ["relatedContent"],
    ProjectDetail: ["mainContent", "relatedContent"],
    ProjectList: ["relatedContent"],
    PublicationDetail: ["relatedContent"],
    PublicationsList: ["relatedContent"],
    ScholarshipsList: ["relatedContent"],
    SearchResults: ["mainContent", "relatedContent"],
    SitemapTemplate: ["relatedContent"],
    SpecializationDetail: ["relatedContent"],
    TeacherDetail: ["mainContent", "relatedContent"],
    TeachersList: ["relatedContent"],
  },
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { content: "Title", tag: "h2" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          humanReadable: true,
        },
        {
          type: "ReferenceField",
          sources: [{ structuredData: "STORIES" }],
          key: "data",
          helptext: "This module will show maximum 20 elements",
        },
        {
          title: "No Results Text",
          type: "TextField",
          key: "noResultsText",
        },
        {
          title: "Content hierarchy for SEO",
          type: "Select",
          key: "cardTitleTag",
          options: [
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "Span" },
          ],
        },
        {
          title: "Link text",
          type: "TextField",
          key: "linkText",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Grouping Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "groupingLink",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Carousel view in mobile devices",
          type: "RadioGroup",
          key: "carouselOnMobile",
          helptext: "It shows a stories in carousel format",
          options: [
            {
              name: "OPT01",
              value: true,
              title: "Yes",
            },
            {
              name: "OPT02",
              value: false,
              title: "No",
            },
          ],
        },
        {
          title: "Design",
          key: "design",
          type: "VisualUniqueSelection",
          mandatory: true,
          options: [
            {
              value: "D001",
              img: StoriesDistributorD001,
            },
            {
              value: "D002",
              img: StoriesDistributorD002,
            },
          ],
          columns: 2,
        },
        {
          title: "Layout",
          key: "layout",
          type: "VisualUniqueSelection",
          mandatory: true,
          options: [
            {
              value: "L001",
              img: StoriesListLayout1,
            },
            {
              value: "L002",
              img: StoriesListLayout2,
            },
          ],
          columns: 2,
          helptext: "Layout of 4 elements per row will be 5 for first design",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },

        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "StoriesDistributor",
    anchorID: null,
    carouselOnMobile: false,
    design: "D001",
    layout: "L001",
    verticalSpacing: "medium",
    title: { content: "Lorem ipsum", tag: "h2" },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    detail: null,
    data: {
      mode: "auto",
      sources: [
        {
          structuredData: "STORIES",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      order: "recent",
      quantity: 4,
      preferenceLanguage: false,
    },
    noResultsText:
      "Sorry, no results were found. Please try a different search.",
    cardTitleTag: "h3",
    linkText: null,
    groupingLink: {
      component: "Link",
      text: "Discover more",
      style: "secondary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
  },
  thumbnails: {
    "1x": StoriesDistributorD001,
    "2x": StoriesDistributorD0012x,
  },
}
