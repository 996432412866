import { getThemeOptions } from "@helpers/schemas"
import {
  EpicAwardsDistributor,
  EpicAwardsDistributor2x,
} from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Epic Awards Distributor",
  component: "EpicAwardsDistributor",
  category: "people",
  dataPacks: ["EPICAWARDS"],
  sectionList: {
    AcademicVideo: ["relatedContent"],
    ArticlesList: ["relatedContent"],
    AudiovisualList: ["relatedContent"],
    BasicTemplate: ["mainContent"],
    EventDetail: ["mainContent", "relatedContent"],
    EventList: ["relatedContent"],
    GeneralInfoDetail: ["gidMainContent", "gidRelatedContent"],
    GeneralInfoList: ["relatedContent"],
    Landing: ["mainContent"],
    NewsDetail: ["relatedContent"],
    NewsList: ["relatedContent"],
    ProgramDetail: ["relatedContent"],
    ProgramsList: ["relatedContent"],
    ProjectDetail: ["mainContent", "relatedContent"],
    ProjectList: ["relatedContent"],
    PublicationDetail: ["relatedContent"],
    PublicationsList: ["relatedContent"],
    ScholarshipsList: ["relatedContent"],
    SearchResults: ["mainContent", "relatedContent"],
    SitemapTemplate: ["relatedContent"],
    SpecializationDetail: ["relatedContent"],
    StoriesList: ["relatedContent"],
    StoryDetail: ["mainContent", "relatedContent"],
    TeacherDetail: ["mainContent", "relatedContent"],
    TeachersList: ["relatedContent"],
  },
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Grouping Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "groupingLink",
          hideable: true,
        },
        {
          type: "ReferenceField",
          sources: [{ structuredData: "EPICAWARDS" }],
          key: "data",
          helptext: "This module will show maximum 20 elements",
        },
        {
          title: "No Results Text",
          type: "TextField",
          key: "noResultsText",
        },
        {
          title: "Link text",
          type: "TextField",
          key: "linkText",
          hideable: true,
          humanReadable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },

        {
          title: "Carousel view in mobile devices",
          type: "RadioGroup",
          key: "carouselOnMobile",
          helptext: "It shows a stories in carousel format",
          options: [
            {
              name: "OPT01",
              value: true,
              title: "Yes",
            },
            {
              name: "OPT02",
              value: false,
              title: "No",
            },
          ],
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "EpicAwardsDistributor",
    carouselOnMobile: false,
    theme: "default",
    verticalSpacing: "medium",
    title: { tag: "h1", content: "Lorem ipsum" },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    detail: null,
    data: {
      mode: "auto",
      sources: [
        {
          structuredData: "EPICAWARDS",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      order: "recent",
      quantity: 4,
      preferenceLanguage: false,
    },
    noResultsText: "Sorry, no results were found.",
    linkText: null,
    groupingLink: {
      component: "Link",
      text: "Discover more",
      style: "secondary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: true,
        noFollow: false,
      },
    },
  },
  thumbnails: {
    "1x": EpicAwardsDistributor,
    "2x": EpicAwardsDistributor2x,
  },
}
