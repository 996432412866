import React, { useEffect, useState } from "react"
import { ThemeProvider, ThemeContext } from "styled-components"

import {
  PageContext,
  PageProvider,
  SiteContext,
  SiteProvider as CoreSiteProvider,
} from "@griddo/core"

import {
  createGlobalTheme,
  createSiteTheme,
  createSubThemes,
  GlobalCSS,
} from "@themes/utils"

import "../config/reset.css"
import "../config/normalize.css"

// CATEGORIES
import { translations } from "@constants"

const AnimationContext = React.createContext("")

const AnimationProvider = props => (
  <AnimationContext.Provider value={{ ...props }}>
    {props.children}
  </AnimationContext.Provider>
)

const StickyContext = React.createContext(null)

const StickyProvider = props => {
  const [sticky, setSticky] = useState({
    FloatingCTA: { open: false },
    ProgramTabs: { open: false },
    QuickFacts: { open: false },
    ScrollIndicator: { open: false },
    SimpleFloatingCTA: { open: false },
    SpecializationDetail: { open: false },
    firstScroll: false,
  })

  const CustomFirstScroll = () => {
    setSticky(p => ({ ...p, firstScroll: true }))
    window.removeEventListener("scroll", CustomFirstScroll)
  }

  const SetFirstScroll = () => {
    if (window !== undefined) {
      if (!sticky.firstScroll) {
        window.addEventListener("scroll", CustomFirstScroll)
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      SetFirstScroll()
    }, 1000)
  }, [])

  return (
    <StickyContext.Provider value={{ ...props, sticky, setSticky }}>
      {props.children}
    </StickyContext.Provider>
  )
}

const SiteProvider = props => {
  const globalTheme = createGlobalTheme()
  // Breakpoints Alias.
  // Ahora es posible también acceder a los breakpoints de las props mediante un
  // objeto:
  // <Box width={{ s: "100px", xl: "300px" }}
  globalTheme.mediaquery.mediaqueries.forEach(mq => {
    globalTheme.breakpoints[mq.label] = mq.minWidth
  })

  const { theme, children, renderer } = props

  const siteTheme = {
    ...createSiteTheme({ theme: theme }),
    subthemes: {
      ...createSubThemes({ theme: theme }),
    },
  }

  // If siteTheme has mediaqueries...
  if (siteTheme.mediaquery) {
    siteTheme.mediaquery.mediaqueries.forEach(mq => {
      siteTheme.breakpoints[mq.label] = mq.minWidth
    })
  }

  // For AX
  const FakeRouterComponent = ({ children, ...props }) => {
    return (
      <a className="gatsby-link" {...props}>
        {children}
      </a>
    )
  }

  const navigate = props => console.log(`Gatsby page will navigate to ${props}`)

  const griddoDamDefaults = {
    quality: 75,
    crop: "cover",
    loading: "lazy",
    decoding: "auto",
    formats: ["webp"],
    gravity: "faces:center",
  }

  const AnimationProviderProps = {
    active: true,
    threshold: 0,
    rootMargin: "-100px",
    duration: 0.4,
    distance: 40,
    animateOnce: true,
    animateBackwards: false,
    delay: 0,
    zoom: false,
    noTransition: false,
  }

  return (
    <CoreSiteProvider
      griddoDamDefaults={griddoDamDefaults}
      translations={translations}
      linkComponent={FakeRouterComponent}
      navigate={navigate}
      {...props}
    >
      {/* Global Theme: breakpoints, fonts, spacing, etc.. */}
      {/* Site Theme: colors + subthemes (colors..) */}
      <ThemeProvider theme={{ ...globalTheme, ...siteTheme }}>
        <AnimationProvider
          showOnScroll={
            renderer === "editor"
              ? { active: false }
              : { active: true, ...AnimationProviderProps }
          }
        >
          <GlobalCSS />
          <React.Suspense fallback={null}>{children}</React.Suspense>
        </AnimationProvider>
      </ThemeProvider>
    </CoreSiteProvider>
  )
}

export {
  PageContext,
  PageProvider,
  SiteContext,
  SiteProvider,
  ThemeProvider,
  ThemeContext,
  AnimationContext,
  AnimationProvider,
  StickyContext,
  StickyProvider,
}
