import { AudiovisualList, AudiovisualList2x } from "@thumbnails/Templates"
import { AudiovisualListLayout1, AudiovisualListLayout2 } from "@layouts"
import {
  CardCollectionLayout1,
  CardCollectionLayout2,
  CardCollectionLayout3,
} from "@layouts"
import { allModulesWhitelist } from "@helpers/schemas"

export default {
  displayName: "Audiovisual List",
  component: "AudiovisualList",
  dataPacks: ["AUDIOVISUAL"],
  type: { label: "Audiovisual", value: "audiovisual" },
  schemaType: "template",
  config: [
    {
      title: "View",
      type: "VisualUniqueSelection",
      key: "view",
      options: [
        {
          value: "viewOne",
          img: AudiovisualListLayout1,
        },
        {
          value: "viewTwo",
          img: AudiovisualListLayout2,
        },
      ],
      columns: 3,
    },
    {
      title: "Layout",
      key: "layout",
      type: "VisualUniqueSelection",
      options: [
        {
          value: "L001",
          img: CardCollectionLayout1,
        },
        {
          value: "L002",
          img: CardCollectionLayout2,
        },
        {
          value: "L003",
          img: CardCollectionLayout3,
        },
      ],
      columns: 2,
    },
  ],
  content: [
    {
      title: "Hero Section",
      type: "ComponentArray",
      maxItems: 1,
      whiteList: ["HeroCarousel", "HeroImage"],
      key: "heroSection",
    },
    {
      title: "Audiovisual Title",
      type: "HeadingField",
      key: "audiovisualTitle",
      advanced: true,
      hideable: true,
      default: { tag: "h2", content: "Audiovisual Center" },
      options: [
        { value: "h1", label: "H1" },
        { value: "h2", label: "H2" },
        { value: "h3", label: "H3" },
        { value: "h4", label: "H4" },
        { value: "span", label: "span" },
      ],
      humanReadable: true,
    },
    {
      title: "Audiovisual Subtitle",
      type: "TextField",
      key: "audiovisualSubtitle",
      hideable: true,
      humanReadable: true,
    },
    {
      title: "Tag Distributor",
      type: "ComponentArray",
      maxItems: 1,
      key: "tagDistributor",
      whiteList: ["TagDistributor"],
    },
    {
      title: "Template filters",
      type: "FieldGroup",
      fields: [
        {
          value: {
            text: "Recommended always selecting the Search Bar if you selected more than three filters.",
          },
          type: "NoteField",
          key: "noteFilters",
        },
        {
          title: "Select template filters",
          type: "CheckGroup",
          key: "templateOptions",
          options: [
            {
              value: "showUnitsFilter",
              title: "Show Units filter",
              name: "unitsFilter",
            },
            {
              value: "showSchoolsFilter",
              title: "Show Schools filter",
              name: "schoolsFilter",
            },
            {
              value: "showPathwaysFilter",
              title: "Show Pathways filter",
              name: "pathwaysFilter",
            },
            {
              value: "showProgramFilter",
              title: "Show Programs filter",
              name: "programFilter",
            },
            {
              value: "showSubjectFilter",
              title: "Show Subject Area & Values filter",
              name: "subjectFilter",
            },
            {
              value: "showTopicFilter",
              title: "Show Topic filter",
              name: "topicFilter",
            },
            {
              value: "showCentersFilter",
              title: "Show Centers & Departments filter",
              name: "centersFilter",
            },
            {
              value: "showSearchbar",
              title: "Show Search Bar",
              name: "searchbar",
            },
          ],
        },
      ],
    },
    {
      title: "Edit filters",
      type: "FieldGroup",
      collapsed: true,
      fields: [
        {
          key: "unitsLabel",
          title: "Units label",
          type: "TextField",
        },
        {
          key: "schoolsLabel",
          title: "Schools label",
          type: "TextField",
        },
        {
          key: "pathwaysLabel",
          title: "Pathways label",
          type: "TextField",
        },
        {
          key: "programsLabel",
          title: "Programs label",
          type: "TextField",
        },
        {
          key: "subjectLabel",
          title: "Subject Area & Values label",
          type: "TextField",
        },
        {
          key: "topicLabel",
          title: "Topic label",
          type: "TextField",
        },
        {
          key: "centersLabel",
          title: "Centers & Departments label",
          type: "TextField",
        },
      ],
    },
    {
      type: "ReferenceField",
      sources: [{ structuredData: "PODCASTS" }, { structuredData: "VIDEOS" }],
      key: "data",
      title: "",
    },
    {
      title: "Content hierarchy for SEO",
      type: "Select",
      key: "cardTitleTag",
      options: [
        { value: "h2", label: "H2" },
        { value: "h3", label: "H3" },
        { value: "h4", label: "H4" },
        { value: "span", label: "Span" },
      ],
    },
    {
      title: "No results text",
      type: "TextField",
      key: "noResultsText",
      hideable: true,
      humanReadable: true,
    },
    {
      title: "Items/page",
      type: "NumberField",
      key: "itemsPerPage",
      default: 12,
      mandatory: true,
    },
    {
      title: "Show description at available audiovisual card",
      type: "RadioGroup",
      key: "showDescription",
      options: [
        {
          value: true,
          title: "Yes",
          name: "yes",
        },
        {
          value: false,
          title: "No",
          name: "no",
        },
      ],
    },
    {
      title: "Show date of audiovisual content",
      type: "RadioGroup",
      key: "showDate",
      options: [
        {
          value: true,
          title: "Yes",
          name: "yes",
        },
        {
          value: false,
          title: "No",
          name: "no",
        },
      ],
    },
    {
      title: "Podcast link text",
      type: "TextField",
      key: "podcastLinkText",
      humanReadable: true,
      helptext: "This only affects to design two.",
    },
    {
      title: "Video link text",
      type: "TextField",
      key: "videoLinkText",
      humanReadable: true,
      helptext: "This only affects to design two.",
    },
    {
      title: "Related Content",
      type: "ComponentArray",
      maxItems: null,
      whiteList: allModulesWhitelist,
      key: "relatedContent",
    },
  ],
  default: {
    type: "template",
    templateType: "AudiovisualList",
    view: "viewOne",
    layout: "L002",
    heroSection: {
      component: "Section",
      name: "Hero Section",
      modules: [],
      sectionPosition: 1,
    },
    audiovisualTitle: { content: "What's going on", tag: "h1" },
    audiovisualSubtitle: "Discover our audiovisual",
    tagDistributor: {
      component: "Section",
      name: "Show Tag Distributor",
      modules: [],
      sectionPosition: 3,
    },
    templateOptions: [],
    data: {
      mode: "auto",
      order: "alpha",
      sources: [
        {
          structuredData: "PODCASTS",
          filterOperator: "OR",
          globalOperator: "AND",
        },
        {
          structuredData: "VIDEOS",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      quantity: 12,
      preferenceLanguage: false,
    },
    cardTitleTag: "h3",
    hasDistributorData: false,
    noResultsText:
      "Sorry, no results were found. Please try a different search.",
    itemsPerPage: 12,
    showDescription: false,
    showDate: true,
    podcastLinkText: null,
    videoLinkText: null,
    relatedContent: {
      component: "Section",
      name: "Related Content",
      modules: [],
      sectionPosition: 2,
    },
  },
  thumbnails: {
    "1x": AudiovisualList,
    "2x": AudiovisualList2x,
  },
}
