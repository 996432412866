export const SCHOOLS = {
  IE: {
    name: "Instituto de empresa",
    path: "/ie-edu",
    logo: "logoCorp",
    logoFooter: "logoCorpFooter",
    logoLight: "logoCorpLight",
    logoMobile: "logoCorpMobile",
    color: "#000066",
  },

  IENYC: {
    name: "Instituto de empresa NYC",
    path: "/ienyc",
    logo: "logoNyc",
    logoFooter: "logoNycLight",
    logoLight: "logoNycLight",
    logoMobile: "logoNyc",
    color: "#000066",
  },

  SUMMERSCHOOL: {
    name: "Summer school",
    path: "/ie-summer-school",
    logo: "logoSummerschool",
    logoFooter: "logoSummerschoolLight",
    logoLight: "logoSummerschoolLight",
    logoMobile: "logoSummerschool",
    color: "#000066",
  },

  UNI: {
    name: "University",
    path: "/university",
    logo: "logoU",
    logoFooter: "logoUFooter",
    logoLight: "logoULight",
    logoMobile: "logoUMobile",
    color: "#000066",
  },

  CLIC: {
    name: "Center for Corporate Learning and Talent Management",
    claim: "IE - Reinventing higher education",
    path: "/center-for-corporate-learning-innovation",
    logo: "logoCcltm",
    logoFooter: "logoCcltmFooter",
    logoLight: "logoCcltmLight",
    color: "#46BEFF",
  },

  XL: {
    name: "Lifelong Learning",
    path: "/lifelong-learning",
    logo: "logoLl",
    logoFooter: "logoLlFooter",
    logoLight: "logoLlLight",
    color: "#000066",
  },

  AL: {
    name: "Alumni",
    path: "/alumni",
    logo: "logoAlumni",
    logoFooter: "logoAlumniFooter",
    logoLight: "logoAlumniLight",
    color: "#000066",
  },

  CR: {
    name: "Corporate Relations",
    path: "/corporate-relations",
    logo: "logoCr",
    logoFooter: "logoCrFooter",
    logoLight: "logoCrLight",
    color: "#000066",
  },

  CGC: {
    name: "Center for the Gobernance of Change",
    path: "/cgc",
    logo: "logoCgc",
    logoFooter: "logoCgcFooter",
    logoLight: "logoCgcLight",
    color: "#000066",
  },

  GPC: {
    name: "Global Policy Center",
    path: "/global-policy-center",
    logo: "logoGpc",
    logoFooter: "logoGpcFooter",
    logoLight: "logoGpcLight",
    color: "#000066",
  },

  ENT: {
    name: "Entrepreneurship",
    path: "/entrepreneurship",
    logo: "logoEntrepreneurship",
    logoFooter: "logoEntrepreneurshipFooter",
    logoLight: "logoEntrepreneurshipLight",
    color: "#000066",
  },

  TC: {
    name: "Talent & Career",
    path: "/talent-and-careers",
    logo: "logoTc",
    logoFooter: "logoTcFooter",
    logoLight: "logoTcLight",
    color: "#000066",
  },

  FOUND: {
    name: "IE Foundation",
    path: "/ie-foundation",
    logo: "logoFound",
    logoFooter: "logoFoundFooter",
    logoLight: "logoFoundLight",
    color: "#000066",
  },

  FAID: {
    name: "Financial Aid",
    path: "/financial-aid",
    logo: "logoFaid",
    logoFooter: "logoFaidFooter",
    logoLight: "logoFaidLight",
    color: "#000066",
  },

  LEAD: {
    name: "Center Middle East",
    path: "/center-leadership-middle-east",
    logo: "logoLeadership",
    logoFooter: "logoLeadershipFooter",
    logoLight: "logoLeadershipLight",
    color: "#000066",
  },

  // Schools

  BUS: {
    name: "Business School",
    path: "/business-school",
    logo: "logoBs",
    logoFooter: "logoBsFooter",
    logoLight: "logoBsLight",
    logoMobile: "logoBsMobile",
    color: "#000066",
  },

  LAW: {
    name: "Law School",
    path: "/law-school",
    logo: "logoLs",
    logoFooter: "logoLsFooter",
    logoLight: "logoLsLight",
    logoMobile: "logoLsMobile",
    color: "#D90011",
  },

  ARQ: {
    name: "School of Architecture and Design",
    path: "/school-of-architecture-and-design",
    logo: "logoSoad",
    logoFooter: "logoSoadFooter",
    logoLight: "logoSoadLight",
    logoMobile: "logoSoadMobile",
    color: "#572672",
  },

  GPA: {
    name: "School of Global and public Affairs",
    logo: "logoSogp",
    logoFooter: "logoSogpFooter",
    logoLight: "logoSogpLight",
    logoMobile: "logoSogpMobile",
    color: "#00808F",
  },

  SPEGA: {
    name: "School of Politics, Economics & Global Affairs",
    path: "/gpa",
    logo: "logoSpega",
    logoFooter: "logoSpegaFooter",
    logoLight: "logoSpegaLight",
    logoMobile: "logoSpegaMobile",
    color: "#00808F",
  },

  HMN: {
    name: "Humanities School",
    path: "/school-of-humanities",
    logo: "logoHumanities",
    logoFooter: "logoHumanitiesFooter",
    logoLight: "logoHumanitiesLight",
    logoMobile: "logoHumanitiesMobile",
    color: "#FF630F",
  },

  HST: {
    name: "School of Science and Technology",
    path: "/hst",
    logo: "logoSohst",
    logoFooter: "logoSohstFooter",
    logoLight: "logoSohstLight",
    logoMobile: "logoSohstMobile",
    color: "#6DC201",
  },

  SST50: {
    name: "SST 50",
    logo: "science",
    logoFooter: "scienceFooter",
    logoLight: "scienceFooter",
    logoMobile: "science",
    color: "#6DC201",
  },

  Architecture50: {
    name: "Architecture 50",
    logo: "architecture",
    logoFooter: "architectureFooter",
    logoLight: "architectureFooter",
    logoMobile: "architecture",
    color: "#572672",
  },

  Business50: {
    name: "Business 50",
    logo: "business",
    logoFooter: "businessFooter",
    logoLight: "businessFooter",
    logoMobile: "business",
    color: "#000066",
  },

  University50: {
    name: "University 50",
    logo: "university",
    logoFooter: "universityFooter",
    logoLight: "universityFooter",
    logoMobile: "university",
    color: "#000066",
  },

  Law50: {
    name: "Law 50",
    logo: "law",
    logoFooter: "lawFooter",
    logoLight: "lawFooter",
    logoMobile: "law",
    color: "#D90011",
  },

  Spega50: {
    name: "Spega 50",
    logo: "spega",
    logoFooter: "spegaFooter",
    logoLight: "spegaFooter",
    logoMobile: "spega",
    color: "#00808F",
  },

  LifelongLearning50: {
    name: "Lifelong learning 50",
    logo: "lifelonglearning",
    logoFooter: "lifelonglearningFooter",
    logoLight: "lifelonglearningFooter",
    logoMobile: "lifelonglearningMobile",
    color: "#000066",
  },

  Humanities50: {
    name: "Humanities 50",
    logo: "humanities",
    logoFooter: "humanitiesFooter",
    logoLight: "humanitiesFooter",
    logoMobile: "humanitiesMobile",
    color: "#FF630F",
  },
}
