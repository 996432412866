import {
  Articles,
  Audiovisual,
  Blog,
  EpicAwards,
  Events,
  GeneralInfo,
  Insights,
  News,
  Offices,
  People,
  Projects,
  Publications,
  Scholarship,
  Specializations,
  Stories,
  Studies,
  StudyPlans,
  Teachers,
  Testimonials,
} from "@thumbnails/DataPacks"

export default {
  ARTICLES: {
    title: "Articles",
    category: "ARTICLES_EVENTS",
    description: "Allows you to hightlight articles.",
    image: Articles,
  },

  AUDIOVISUAL: {
    title: "Audiovisual",
    category: "ARTICLES_EVENTS",
    description:
      "It shows last videos and podcast from content type videos and podcast.",
    image: Audiovisual,
  },

  BLOG: {
    title: "Blog",
    category: "INTERACTIVE",
    description: "Allows you to publish the blog from an external source.",
    image: Blog,
  },

  EPICAWARDS: {
    title: "EpicAwards",
    category: "PEOPLE",
    description:
      "Template to show the stories of epic awards nominated and winners.",
    image: EpicAwards,
  },

  EVENTS: {
    title: "Events",
    category: "ARTICLES_EVENTS",
    description:
      "It shows the next events, being able to select if you want to show specific ones manually. It also adds the functionality of Add to calendar.",
    image: Events,
  },

  GENERALINFO: {
    title: "GeneralInfo",
    category: "GENERALINFO",
    description: "It allows to use General Info content type.",
    image: GeneralInfo,
  },

  INSIGHTS: {
    title: "Insights",
    category: "INSIGHTS",
    description: "",
    image: Insights,
  },

  OFFICES: {
    title: "Location",
    category: "OFFICES",
    description: "List of international offices, clubs and partners.",
    image: Offices,
  },

  NEWS: {
    title: "News",
    category: "ARTICLES_EVENTS",
    description: "It shows the news of the sector and the schools.",
    image: News,
  },

  PEOPLE: {
    title: "People",
    category: "PEOPLE",
    description:
      "It shows a list of people outside the School who collaborate by giving talks and seminars.",
    image: People,
  },

  PROJECTS: {
    title: "Projects",
    category: "PROJECTS",
    description:
      "Template to show the single project of different profiles (students, teachers).",
    image: Projects,
  },

  PUBLICATIONS: {
    title: "Publications",
    category: "ARTICLES_EVENTS",
    description: "It shows books or publications of the sectors.",
    image: Publications,
  },

  SCHOLARSHIP: {
    title: "Scholarship",
    category: "FINANTIAL",
    description: "",
    image: Scholarship,
  },

  SPECIALIZATIONS: {
    title: "Specializations",
    category: "STUDIES",
    description: "Template for presenting the specializations of a program.",
    image: Specializations,
  },

  STORIES: {
    title: "Stories",
    category: "PEOPLE",
    description: "It allows to use Stories content type of different profiles.",
    image: Stories,
  },

  STUDIES: {
    title: "Studies",
    category: "STUDIES",
    description: "Template to Programs.",
    image: Studies,
  },

  STUDYPLANS: {
    title: "StudyPlans",
    category: "STUDIES",
    description:
      "It allows to put a study plan content into an accordion with a table on the side. Each element has embedded established components.",
    image: StudyPlans,
  },

  TEACHERS: {
    title: "Teachers",
    category: "STUDIES",
    description: "It allows to use Teachers content type.",
    image: Teachers,
  },

  TESTIMONIALS: {
    title: "Testimonials",
    category: "PEOPLE",
    description: "Allows you to display several testimonials on one carousel.",
    image: Testimonials,
  },
}
