import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import { HeroImage, HeroImage2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Hero Landing",
  component: "HeroLanding",
  category: "hero",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Logo",
          type: "Select",
          key: "school",
          mandatory: true,
          options: [
            { value: "IE", label: "Instituto de empresa" },
            { value: "IENYC", label: "Instituto de empresa NYC" },
            { value: "SUMMERSCHOOL", label: "Summer school" },
            { value: "BUS", label: "Business School" },
            {
              value: "CLIC",
              label: "Center for Corporate Learning and Talent Management",
            },
            { value: "XL", label: "Lifelong Learning" },
            { value: "LAW", label: "Law School" },
            { value: "ARQ", label: "School of Architecture and Design" },
            { value: "GPA", label: "School of Global and public Affairs" },
            {
              value: "SPEGA",
              label: "School of Politics, Economics & Global Affairs",
            },
            { value: "HMN", label: "Humanities School" },
            { value: "HST", label: "School of Science and Technology" },
            { value: "AL", label: "Alumni" },
            { value: "CGC", label: "Center for the Gobernance of Change" },
            { value: "GPC", label: "Global Policy Center" },
            { value: "CR", label: "Corporate Relations" },
            { value: "ENT", label: "Entrepreneurship" },
            { value: "UNI", label: "University" },
            { value: "TC", label: "Talent & Career" },
            { value: "FOUND", label: "IE Foundation" },
            { value: "FAID", label: "Financial Aid" },
            { value: "SST50", label: "SST 50" },
            { value: "Architecture50", label: "Architecture 50" },
            { value: "Business50", label: "Business 50" },
            { value: "Law50", label: "Law 50" },
            { value: "Spega50", label: "SPEGA 50" },
            { value: "University50", label: "University 50" },
            { value: "LifelongLearning50", label: "LifelongLearning 50" },
            { value: "Humanities50", label: "Humanities 50" },
          ],
        },
        {
          title: "Subtitle",
          type: "TextField",
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "TextField",
          key: "detail",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link",
          hideable: true,
        },
        {
          title: "Background Image",
          type: "ComponentContainer",
          whiteList: ["Image"],
          key: "image",
          helptext: "Recommended minimum image size: 2508x1030",
        },
        {
          title: "Elements",
          type: "ComponentArray",
          maxItems: null,
          whiteList: ["Tag"],
          key: "elements",
          mandatory: true,
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "HeroLanding",
    title: { content: "Title", tag: "h1" },
    school: "IE",
    subtitle: "Lorem Ipsum",
    detail: "Lorem Ipsum",
    link: {
      component: "LinkModal",
      style: "primary",
      link: {
        text: "Link",
        linkType: "url",
        url: {
          href: null,
          linkToURL: null,
          linkTo: null,
          newTab: false,
          noFollow: false,
        },
        modal: {
          requestInfo: {
            component: "RequestInfo",
          },
          floatingCTAMail: {
            component: "FloatingCTAMail",
          },
          floatingCTALightbox: {
            component: "FloatingCTALightbox",
          },
          floatingCTAVideo: {
            component: "FloatingCTAVideo",
          },
        },
      },
    },
    image: {
      component: "Image",
      ...DEFAULT_IMAGE_COMPONENT,
    },
    elements: [
      {
        component: "Tag",
        title: { content: "Title" },
        url: {
          external: true,
          href: "",
          icon: "",
          label: "",
          newTab: false,
          noFollow: false,
          linkTo: null,
        },
      },
      {
        component: "Tag",
        title: { content: "Title" },
        url: {
          external: true,
          href: "",
          icon: "",
          label: "",
          newTab: false,
          noFollow: false,
          linkTo: null,
        },
      },
      {
        component: "Tag",
        title: { content: "Title" },
        url: {
          external: true,
          href: "",
          icon: "",
          label: "",
          newTab: false,
          noFollow: false,
          linkTo: null,
        },
      },
    ],
  },
  thumbnails: {
    "1x": HeroImage,
    "2x": HeroImage2x,
  },
}
