import { NewsDistributor, NewsDistributor2x } from "@thumbnails/Modules"
import {
  NewsDistributorDesign1,
  NewsDistributorDesign2,
  NewsDistributorDesign3,
  NewsDistributorDesign4,
} from "@designs"

export default {
  schemaType: "module",
  displayName: "News Distributor",
  component: "NewsDistributor",
  category: "articlesAndEvents",
  dataPacks: ["NEWS"],
  sectionList: {
    AcademicVideo: ["relatedContent"],
    ArticlesList: ["relatedContent"],
    AudiovisualList: ["relatedContent"],
    BasicTemplate: ["mainContent"],
    EpicAwardDetail: ["mainContent", "relatedContent"],
    EpicAwardList: ["relatedContent"],
    EventDetail: ["mainContent", "relatedContent"],
    EventList: ["relatedContent"],
    GeneralInfoDetail: ["gidMainContent", "gidRelatedContent"],
    GeneralInfoList: ["relatedContent"],
    Landing: ["mainContent"],
    ProgramDetail: ["relatedContent"],
    ProgramsList: ["relatedContent"],
    ProjectDetail: ["mainContent", "relatedContent"],
    ProjectList: ["relatedContent"],
    PublicationDetail: ["relatedContent"],
    PublicationsList: ["relatedContent"],
    ScholarshipsList: ["relatedContent"],
    SearchResults: ["mainContent", "relatedContent"],
    SitemapTemplate: ["relatedContent"],
    SpecializationDetail: ["relatedContent"],
    StoriesList: ["relatedContent"],
    StoryDetail: ["mainContent", "relatedContent"],
    TeacherDetail: ["mainContent", "relatedContent"],
    TeachersList: ["relatedContent"],
  },
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          humanReadable: true,
        },
        {
          type: "ReferenceField",
          sources: [{ structuredData: "NEWS" }],
          key: "data",
          helptext:
            "This module must contain 3 to 6 news in design 1 and 2, and maximum of 4 news in design 3 and 4.",
        },
        {
          title: "No Results Text",
          type: "TextField",
          key: "noResultsText",
          humanReadable: true,
        },
        {
          title: "Where should the news link go?",
          type: "RadioGroup",
          key: "linkDirection",
          mandatory: false,
          helptext: "This only affects to automatic mode.",
          options: [
            {
              value: "pageOfCanonical",
              title: "Page of the canonical site",
              name: "pageOfCanonical",
            },
            {
              value: "pageOfSite",
              title: "Page of this site",
              name: "pageOfSite",
            },
          ],
        },
        {
          title: "Content hierarchy for SEO",
          type: "Select",
          key: "cardTitleTag",
          options: [
            {
              value: "H2",
              label: "h2",
            },
            {
              value: "H3",
              label: "h3",
            },
            {
              value: "H4",
              label: "h4",
            },
            {
              value: "span",
              label: "span",
            },
          ],
        },
        {
          title: "Grouping Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "groupingLink",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Design",
          key: "design",
          type: "VisualUniqueSelection",
          options: [
            {
              value: "simple",
              img: NewsDistributorDesign1,
            },
            {
              value: "featuredBlock",
              img: NewsDistributorDesign2,
            },
            {
              value: "blockCard",
              img: NewsDistributorDesign3,
            },
            {
              value: "blockMultipleCards",
              img: NewsDistributorDesign4,
            },
          ],
          columns: 3,
        },
        {
          title: "Element style",
          key: "elementStyle",
          type: "RadioGroup",
          helptext: "",
          options: [
            {
              name: "magazineCard",
              value: "magazineCard",
              title: "Magazine Card",
            },
            {
              name: "magazineCardReduced",
              value: "magazineCardReduced",
              title: "Magazine Card Reduced",
            },
          ],
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "NewsDistributor",
    anchorID: null,
    verticalSpacing: "medium",
    design: "featuredBlock",
    elementStyle: "magazineCard",
    title: {
      content: "Title",
      tag: "h2",
    },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    detail: null,
    data: {
      mode: "auto",
      sources: [
        {
          structuredData: "NEWS",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      order: "recent",
      quantity: 3,
      preferenceLanguage: false,
    },
    noResultsText:
      "Sorry, no results were found. Please try a different search.",
    cardTitleTag: "h3",
    linkDirection: "pageOfSite",
    groupingLink: {
      component: "Link",
      text: "Discover more news",
      style: "secondary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
  },
  thumbnails: {
    "1x": NewsDistributor,
    "2x": NewsDistributor2x,
  },
}
