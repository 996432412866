import React from "react"
import { Component as CoreComponent, mapLibrary } from "@adapters"
import AnchorWrapper from "@elements/AnchorWrapper"

const Accordion = React.lazy(() =>
  import(
    "./Accordion/Accordion" /* webpackChunkName: "module---src-Accordion" */
  )
)
const Accordion50 = React.lazy(() =>
  import(
    "./Accordion50/Accordion50" /* webpackChunkName: "module---src-Accordion50" */
  )
)
const AddressCollection = React.lazy(() =>
  import(
    "./AddressCollection/AddressCollection" /* webpackChunkName: "module---src-AddressCollection" */
  )
)
const ArticlesDistributor = React.lazy(() =>
  import(
    "./ArticlesDistributor/ArticlesDistributor" /* webpackChunkName: "module---src-ArticlesDistributor" */
  )
)
const AudiovisualDistributor = React.lazy(() =>
  import(
    "./AudiovisualDistributor/AudiovisualDistributor" /* webpackChunkName: "module---src-AudiovisualDistributor" */
  )
)
const BasicCard = React.lazy(() =>
  import(
    "../components/BasicCard/BasicCard" /* webpackChunkName: "module---src-BasicCard" */
  )
)
const BasicContent = React.lazy(() =>
  import(
    "./BasicContent/BasicContent" /* webpackChunkName: "module---src-BasicContent" */
  )
)
const BasicWysiwyg = React.lazy(() =>
  import(
    "./BasicWysiwyg/BasicWysiwyg" /* webpackChunkName: "module---src-BasicWysiwyg" */
  )
)
const BlogDistributor = React.lazy(() =>
  import(
    "./BlogDistributor/BlogDistributor" /* webpackChunkName: "module---src-BlogDistributor" */
  )
)
const CardCollection = React.lazy(() =>
  import(
    "./CardCollection/CardCollection" /* webpackChunkName: "module---src-CardCollection" */
  )
)
const ChartCollection = React.lazy(() =>
  import(
    "./ChartCollection/ChartCollection" /* webpackChunkName: "module---src-ChartCollection" */
  )
)
const Countdown = React.lazy(() =>
  import(
    "./Countdown/Countdown" /* webpackChunkName: "module---src-Countdown" */
  )
)
const CTACollection = React.lazy(() =>
  import(
    "./CTACollection/CTACollection" /* webpackChunkName: "module---src-CTACollection" */
  )
)
const CypherCollection = React.lazy(() =>
  import(
    "./CypherCollection/CypherCollection" /* webpackChunkName: "module---src-CypherCollection" */
  )
)
const DocumentDownload = React.lazy(() =>
  import(
    "./DocumentDownload/DocumentDownload" /* webpackChunkName: "module---src-DocumentDownload" */
  )
)
const DownloadCollection = React.lazy(() =>
  import(
    "./DownloadCollection/DownloadCollection" /* webpackChunkName: "module---src-DownloadCollection" */
  )
)
const DownloadForm = React.lazy(() =>
  import(
    "./DownloadForm/DownloadForm" /* webpackChunkName: "module---src-DownloadForm" */
  )
)
const EpicAwardsDistributor = React.lazy(() =>
  import(
    "./EpicAwardsDistributor/EpicAwardsDistributor" /* webpackChunkName: "module---src-EpicAwardsDistributor" */
  )
)
const EventsDistributor = React.lazy(() =>
  import(
    "./EventsDistributor/EventsDistributor" /* webpackChunkName: "module---src-EventsDistributor" */
  )
)
const FeaturedAudiovisual = React.lazy(() =>
  import(
    "./FeaturedAudiovisual/FeaturedAudiovisual" /* webpackChunkName: "module---src-FeaturedAudiovisual" */
  )
)
const FeaturedBlock = React.lazy(() =>
  import(
    "./FeaturedBlock/FeaturedBlock" /* webpackChunkName: "module---src-FeaturedBlock" */
  )
)
const FeaturedBluePanel = React.lazy(() =>
  import(
    "./FeaturedBluePanel/FeaturedBluePanel" /* webpackChunkName: "module---src-FeaturedBluePanel" */
  )
)
const FeaturedProgram = React.lazy(() =>
  import(
    "./FeaturedProgram/FeaturedProgram" /* webpackChunkName: "module---src-FeaturedProgram" */
  )
)
const FeaturedTextBluePanels = React.lazy(() =>
  import(
    "./FeaturedTextBluePanels/FeaturedTextBluePanels" /* webpackChunkName: "module---src-FeaturedTextBluePanels" */
  )
)
const FeedbackForm = React.lazy(() =>
  import(
    "./FeedbackForm/FeedbackForm" /* webpackChunkName: "module---src-FeedbackForm" */
  )
)
const FloatingCTA = React.lazy(() =>
  import(
    "./FloatingCTA/FloatingCTA" /* webpackChunkName: "module---src-FloatingCTA" */
  )
)
const FloatingCTALightbox = React.lazy(() =>
  import(
    "./FloatingCTALightbox/FloatingCTALightbox" /* webpackChunkName: "module---src-FloatingCTALightbox" */
  )
)
const FloatingCTAMail = React.lazy(() =>
  import(
    "./FloatingCTAMail/FloatingCTAMail" /* webpackChunkName: "module---src-FloatingCTAMail" */
  )
)
const FloatingCTAVideo = React.lazy(() =>
  import(
    "./FloatingCTAVideo/FloatingCTAVideo" /* webpackChunkName: "module---src-FloatingCTAVideo" */
  )
)
const Footer = React.lazy(() =>
  import("./Footer/Footer" /* webpackChunkName: "module---src-Footer" */)
)
const FullWidthForm = React.lazy(() =>
  import(
    "./FullWidthForm/FullWidthForm" /* webpackChunkName: "module---src-FullWidthForm" */
  )
)
const GeneralInfoDistributor = React.lazy(() =>
  import(
    "./GeneralInfoDistributor/GeneralInfoDistributor" /* webpackChunkName: "module---src-GeneralInfoDistributor" */
  )
)
import Header from "./Header/Header"
import HeroCarousel from "./HeroCarousel/HeroCarousel"
const HeroClaim = React.lazy(() =>
  import(
    "./HeroClaim/HeroClaim" /* webpackChunkName: "module---src-HeroClaim" */
  )
)
const HeroImage = React.lazy(() =>
  import(
    "./HeroImage/HeroImage" /* webpackChunkName: "module---src-HeroImage" */
  )
)
const HeroLanding = React.lazy(() =>
  import(
    "./HeroLanding/HeroLanding" /* webpackChunkName: "module---src-HeroLanding" */
  )
)
const HeroMosaic = React.lazy(() =>
  import(
    "./HeroMosaic/HeroMosaic" /* webpackChunkName: "module---src-HeroMosaic" */
  )
)
const HeroProgram = React.lazy(() =>
  import(
    "./HeroProgram/HeroProgram" /* webpackChunkName: "module---src-HeroProgram" */
  )
)
const HeroProgramVideo = React.lazy(() =>
  import(
    "./HeroProgramVideo/HeroProgramVideo" /* webpackChunkName: "module---src-HeroProgramVideo" */
  )
)
const HeroQuote = React.lazy(() =>
  import(
    "./HeroQuote/HeroQuote" /* webpackChunkName: "module---src-HeroQuote" */
  )
)
const HeroSidePanel = React.lazy(() =>
  import(
    "./HeroSidePanel/HeroSidePanel" /* webpackChunkName: "module---src-HeroSidePanel" */
  )
)
const HeroSlider = React.lazy(() =>
  import(
    "./HeroSlider/HeroSlider" /* webpackChunkName: "module---src-HeroSlider" */
  )
)
const HeroSpecialization = React.lazy(() =>
  import(
    "./HeroSpecialization/HeroSpecialization" /* webpackChunkName: "module---src-HeroSpecialization" */
  )
)
const HeroText = React.lazy(() =>
  import("./HeroText/HeroText" /* webpackChunkName: "module---src-HeroText" */)
)
const HeroVideo = React.lazy(() =>
  import(
    "./HeroVideo/HeroVideo" /* webpackChunkName: "module---src-HeroVideo" */
  )
)
const HorizontalTabs = React.lazy(() =>
  import(
    "./HorizontalTabs/HorizontalTabs" /* webpackChunkName: "module---src-HorizontalTabs" */
  )
)
const Iframe = React.lazy(() =>
  import("./Iframe/Iframe" /* webpackChunkName: "module---src-Iframe" */)
)
const IframeQuiz = React.lazy(() =>
  import(
    "./IframeQuiz/IframeQuiz" /* webpackChunkName: "module---src-IframeQuiz" */
  )
)
const ImageGallery = React.lazy(() =>
  import(
    "./ImageGallery/ImageGallery" /* webpackChunkName: "module---src-ImageGallery" */
  )
)
const InformationStructure = React.lazy(() =>
  import(
    "./InformationStructure/InformationStructure" /* webpackChunkName: "module---src-InformationStructure" */
  )
)
const InformativeHeader = React.lazy(() =>
  import(
    "./InformativeHeader/InformativeHeader" /* webpackChunkName: "module---src-InformativeHeader" */
  )
)
const InsightsDistributor = React.lazy(() =>
  import(
    "./InsightsDistributor/InsightsDistributor" /* webpackChunkName: "module---src-InsightsDistributor" */
  )
)
const Intro = React.lazy(() =>
  import("./Section/Intro" /* webpackChunkName: "module---src-Intro" */)
)
const IntroForm = React.lazy(() =>
  import(
    "./IntroForm/IntroForm" /* webpackChunkName: "module---src-IntroForm" */
  )
)
const IntroTextCard = React.lazy(() =>
  import(
    "./IntroTextCard/IntroTextCard" /* webpackChunkName: "module---src-IntroTextCard" */
  )
)
const ListCollapsed = React.lazy(() =>
  import(
    "./ListCollapsed/ListCollapsed" /* webpackChunkName: "module---src-ListCollapsed" */
  )
)
const ListFilters = React.lazy(() =>
  import(
    "./ListFilters/ListFilters" /* webpackChunkName: "module---src-ListFilters" */
  )
)
const LoadScript = React.lazy(() =>
  import(
    "./LoadScript/LoadScript" /* webpackChunkName: "module---src-LoadScript" */
  )
)
const LocationMap = React.lazy(() =>
  import(
    "./LocationMap/LocationMap" /* webpackChunkName: "module---src-LocationMap" */
  )
)
const LogoCollection = React.lazy(() =>
  import(
    "./LogoCollection/LogoCollection" /* webpackChunkName: "module---src-LogoCollection" */
  )
)
const MasonryCollection = React.lazy(() =>
  import(
    "./MasonryCollection/MasonryCollection" /* webpackChunkName: "module---src-MasonryCollection" */
  )
)
const Milestones = React.lazy(() =>
  import(
    "./Milestones/Milestones" /* webpackChunkName: "module---src-Milestones" */
  )
)
const Module = React.lazy(() =>
  import("./Module/Module" /* webpackChunkName: "module---src-Module" */)
)
const ModuleContainer = React.lazy(() =>
  import(
    "./ModuleContainer" /* webpackChunkName: "module---src-ModuleContainer" */
  )
)
const ModuleTitle = React.lazy(() =>
  import("./ModuleTitle" /* webpackChunkName: "module---src-ModuleTitle" */)
)
const MultiPage = React.lazy(() =>
  import(
    "./MultiPage/MultiPage" /* webpackChunkName: "module---src-MultiPage" */
  )
)
const NewsDistributor = React.lazy(() =>
  import(
    "./NewsDistributor/NewsDistributor" /* webpackChunkName: "module---src-NewsDistributor" */
  )
)
const Pagination = React.lazy(() =>
  import(
    "./Pagination/Pagination" /* webpackChunkName: "module---src-Pagination" */
  )
)
const PersonDistributor = React.lazy(() =>
  import(
    "./PersonDistributor/PersonDistributor" /* webpackChunkName: "module---src-PersonDistributor" */
  )
)
const PopUp = React.lazy(() =>
  import("./PopUp/PopUp" /* webpackChunkName: "module---src-PopUp" */)
)
const Principles = React.lazy(() =>
  import(
    "./Principles/Principles" /* webpackChunkName: "module---src-Principles" */
  )
)
const ProfileCollection = React.lazy(() =>
  import(
    "./ProfileCollection/ProfileCollection" /* webpackChunkName: "module---src-ProfileCollection" */
  )
)
const ProgramDistributor = React.lazy(() =>
  import(
    "./ProgramDistributor/ProgramDistributor" /* webpackChunkName: "module---src-ProgramDistributor" */
  )
)
const ProgramFinder = React.lazy(() =>
  import(
    "./ProgramFinder/ProgramFinder" /* webpackChunkName: "module---src-ProgramFinder" */
  )
)
const ProgramQuickFacts = React.lazy(() =>
  import(
    "./ProgramQuickFacts/ProgramQuickFacts" /* webpackChunkName: "module---src-ProgramQuickFacts" */
  )
)
const ProgramsPresentation = React.lazy(() =>
  import(
    "./ProgramsPresentation/ProgramsPresentation" /* webpackChunkName: "module---src-ProgramsPresentation" */
  )
)
const ProgramsPresentationAuto = React.lazy(() =>
  import(
    "./ProgramsPresentationAuto/ProgramsPresentationAuto" /* webpackChunkName: "module---src-ProgramsPresentation" */
  )
)
const ProgramTabs = React.lazy(() =>
  import(
    "./ProgramTabs/ProgramTabs" /* webpackChunkName: "module---src-ProgramTabs" */
  )
)
const ProjectDistributor = React.lazy(() =>
  import(
    "./ProjectDistributor/ProjectDistributor" /* webpackChunkName: "module---src-ProjectDistributor" */
  )
)
const PublicationDistributor = React.lazy(() =>
  import(
    "./PublicationDistributor/PublicationDistributor" /* webpackChunkName: "module---src-PublicationDistributor" */
  )
)
const RegistrationWall = React.lazy(() =>
  import(
    "./RegistrationWall/RegistrationWall" /* webpackChunkName: "module---src-RegistrationWall" */
  )
)
const RequestInfo = React.lazy(() =>
  import(
    "./RequestInfo/RequestInfo" /* webpackChunkName: "module---src-RequestInfo" */
  )
)
const RequestInfoCard = React.lazy(() =>
  import(
    "./RequestInfoCard/RequestInfoCard" /* webpackChunkName: "module---src-RequestInfoCard" */
  )
)
const RequestInfoCollection = React.lazy(() =>
  import(
    "./RequestInfoCollection/RequestInfoCollection" /* webpackChunkName: "module---src-RequestInfoCollection" */
  )
)
const RequestInfoWithModal = React.lazy(() =>
  import(
    "./RequestInfoWithModal/RequestInfoWithModal" /* webpackChunkName: "module---src-RequestInfoWithModal" */
  )
)
const ScholarshipsDistributor = React.lazy(() =>
  import(
    "./ScholarshipsDistributor/ScholarshipsDistributor" /* webpackChunkName: "module---src-ScholarshipsDistributor" */
  )
)
const SchoolFooter = React.lazy(() =>
  import(
    "./SchoolFooter/SchoolFooter" /* webpackChunkName: "module---src-SchoolFooter" */
  )
)
const ScrollIndicator = React.lazy(() =>
  import(
    "./ScrollIndicator/ScrollIndicator" /* webpackChunkName: "module---src-ScrollIndicator" */
  )
)
const SearchModule = React.lazy(() =>
  import(
    "./SearchModule/SearchModule" /* webpackChunkName: "module---src-SearchModule" */
  )
)
const Section = React.lazy(() =>
  import("./Section/Section" /* webpackChunkName: "module---src-Section" */)
)
const SimpleFloatingCTA = React.lazy(() =>
  import(
    "./SimpleFloatingCTA/SimpleFloatingCTA" /* webpackChunkName: "module---src-SimpleFloatingCTA" */
  )
)
const Sitemap = React.lazy(() =>
  import("./Sitemap/Sitemap" /* webpackChunkName: "module---src-Sitemap" */)
)
const Slider = React.lazy(() =>
  import("./Slider/Slider" /* webpackChunkName: "module---src-Slider" */)
)
const SliderPagination = React.lazy(() =>
  import(
    "./SliderPagination/SliderPagination" /* webpackChunkName: "module---src-SliderPagination" */
  )
)
const SocialMedia = React.lazy(() =>
  import(
    "./SocialMedia/SocialMedia" /* webpackChunkName: "module---src-SocialMedia" */
  )
)
const SocialShare = React.lazy(() =>
  import(
    "./SocialShare/SocialShare" /* webpackChunkName: "module---src-SocialShare" */
  )
)
const Spacer = React.lazy(() =>
  import("./Spacer/Spacer" /* webpackChunkName: "module---src-Spacer" */)
)
const SpecializationDistributor = React.lazy(() =>
  import(
    "./SpecializationDistributor/SpecializationDistributor" /* webpackChunkName: "module---src-SpecializationDistributor" */
  )
)
const StepsCollection = React.lazy(() =>
  import(
    "./StepsCollection/StepsCollection" /* webpackChunkName: "module---src-StepsCollection" */
  )
)
const StoriesDistributor = React.lazy(() =>
  import(
    "./StoriesDistributor/StoriesDistributor" /* webpackChunkName: "module---src-StoriesDistributor" */
  )
)
const StudentPath = React.lazy(() =>
  import(
    "./StudentPath/StudentPath" /* webpackChunkName: "module---src-StudentPath" */
  )
)
const StudyPlan = React.lazy(() =>
  import(
    "./StudyPlan/StudyPlan" /* webpackChunkName: "module---src-StudyPlan" */
  )
)
const Table = React.lazy(() =>
  import("./Table/Table" /* webpackChunkName: "module---src-Table" */)
)
const TagDistributor = React.lazy(() =>
  import(
    "./TagDistributor/TagDistributor" /* webpackChunkName: "module---src-TagDistributor" */
  )
)
const TagsCloud = React.lazy(() =>
  import(
    "./TagsCloud/TagsCloud" /* webpackChunkName: "module---src-TagsCloud" */
  )
)
const Testimonial = React.lazy(() =>
  import(
    "./Testimonial/Testimonial" /* webpackChunkName: "module---src-Testimonial" */
  )
)
const Timeline = React.lazy(() =>
  import("./Timeline/Timeline" /* webpackChunkName: "module---src-Timeline" */)
)
const TypographicVideo = React.lazy(() =>
  import(
    "./TypographicVideo/TypographicVideo" /* webpackChunkName: "module---src-TypographicVideo" */
  )
)
const VerticalSlider = React.lazy(() =>
  import(
    "./VerticalSlider/VerticalSlider" /* webpackChunkName: "module---src-VerticalSlider" */
  )
)
const VerticalTabs = React.lazy(() =>
  import(
    "./VerticalTabs/VerticalTabs" /* webpackChunkName: "module---src-VerticalTabs" */
  )
)
const VideoCollection = React.lazy(() =>
  import(
    "./VideoCollection/VideoCollection" /* webpackChunkName: "module---src-VideoCollection" */
  )
)
const VideoGallery = React.lazy(() =>
  import(
    "./VideoGallery/VideoGallery" /* webpackChunkName: "module---src-VideoGallery" */
  )
)
const VideoPlaylist = React.lazy(() =>
  import(
    "./VideoPlaylist/VideoPlaylist" /* webpackChunkName: "module---src-VideoPlaylist" */
  )
)
const WebinarCollection = React.lazy(() =>
  import(
    "./WebinarCollection/WebinarCollection" /* webpackChunkName: "module---src-WebinarCollection" */
  )
)
const Wysiwyg = React.lazy(() =>
  import("./Wysiwyg/Wysiwyg" /* webpackChunkName: "module---src-Wysiwyg" */)
)

const EXPORTS = {
  Accordion,
  Accordion50,
  AddressCollection,
  ArticlesDistributor,
  AudiovisualDistributor,
  BasicCard,
  BasicContent,
  BasicWysiwyg,
  BlogDistributor,
  CardCollection,
  ChartCollection,
  Countdown,
  CTACollection,
  CypherCollection,
  DocumentDownload,
  DownloadCollection,
  DownloadForm,
  EpicAwardsDistributor,
  EventsDistributor,
  FeaturedAudiovisual,
  FeaturedBlock,
  FeaturedBluePanel,
  FeaturedProgram,
  FeaturedTextBluePanels,
  FeedbackForm,
  FloatingCTA,
  FloatingCTALightbox,
  FloatingCTAMail,
  FloatingCTAVideo,
  Footer,
  FullWidthForm,
  GeneralInfoDistributor,
  Header,
  HeroCarousel,
  HeroClaim,
  HeroImage,
  HeroLanding,
  HeroMosaic,
  HeroProgram,
  HeroProgramVideo,
  HeroQuote,
  HeroSidePanel,
  HeroSlider,
  HeroSpecialization,
  HeroText,
  HeroVideo,
  HorizontalTabs,
  Iframe,
  IframeQuiz,
  ImageGallery,
  InformationStructure,
  InformativeHeader,
  InsightsDistributor,
  Intro,
  IntroForm,
  IntroTextCard,
  ListCollapsed,
  ListFilters,
  LoadScript,
  LocationMap,
  LogoCollection,
  MasonryCollection,
  Milestones,
  Module,
  ModuleContainer,
  ModuleTitle,
  MultiPage,
  NewsDistributor,
  Pagination,
  PersonDistributor,
  PopUp,
  Principles,
  ProfileCollection,
  ProgramDistributor,
  ProgramFinder,
  ProgramQuickFacts,
  ProgramsPresentation,
  ProgramsPresentationAuto,
  ProgramTabs,
  ProjectDistributor,
  PublicationDistributor,
  RegistrationWall,
  RequestInfo,
  RequestInfoCard,
  RequestInfoCollection,
  RequestInfoWithModal,
  ScholarshipsDistributor,
  SchoolFooter,
  ScrollIndicator,
  SearchModule,
  Section,
  SimpleFloatingCTA,
  Sitemap,
  Slider,
  SliderPagination,
  SocialMedia,
  SocialShare,
  Spacer,
  SpecializationDistributor,
  StepsCollection,
  StoriesDistributor,
  StudentPath,
  StudyPlan,
  Table,
  TagDistributor,
  TagsCloud,
  Testimonial,
  Timeline,
  TypographicVideo,
  VerticalSlider,
  VerticalTabs,
  VideoCollection,
  VideoGallery,
  VideoPlaylist,
  WebinarCollection,
  Wysiwyg,
}

const modules = mapLibrary({
  ...EXPORTS,
})

const ModuleFromList = props => {
  const notLazy = ["Header", "HeroCarousel"]
  const toRender = (
    <AnchorWrapper>
      <CoreComponent libComponents={modules} {...props} />
    </AnchorWrapper>
  )

  if (notLazy.includes(props.component)) {
    return toRender
  } else {
    return <React.Suspense fallback={null}>{toRender}</React.Suspense>
  }
}

export default EXPORTS

export { ModuleFromList }
