import { allModulesWhitelist } from "@helpers/schemas"
import { ProgramDetail, ProgramDetail2x } from "@thumbnails/Templates"

// Defaults from modules
import HeroProgram from "@schemas/modules/HeroProgram"
import ProgramQuickFacts from "@schemas/modules/ProgramQuickFacts"
import ProgramTabs from "@schemas/modules/ProgramTabs"
import SocialShare from "@schemas/modules/SocialShare"

export default {
  schemaType: "template",
  displayName: "Program Detail",
  component: "ProgramDetail",
  dataPacks: ["STUDIES"],
  type: { label: "Programs", value: "programs", mode: "detail" },
  config: [
    {
      key: "online",
      type: "UniqueCheck",
      helptext: "Corresponds to Online and Virtual program.",
      options: [
        {
          title: "Online",
        },
      ],
    },
    {
      key: "durationDataOnline",
      type: "MultiCheckSelect",
      title: "Online Duration",
      placeholder: "Select a Duration",
      source: "DURATION",
    },
    {
      key: "onsite",
      type: "UniqueCheck",
      helptext: "Corresponds to Face to Face and Presencial program.",
      options: [
        {
          title: "Onsite",
        },
      ],
    },
    {
      key: "durationDataOnsite",
      type: "MultiCheckSelect",
      title: "Onsite Duration",
      placeholder: "Select a Duration",
      source: "DURATION",
    },
    {
      key: "locationDataOnsite",
      type: "MultiCheckSelect",
      title: "Onsite Location",
      placeholder: "Select a Location",
      source: "LOCATION",
    },
    {
      key: "blended",
      type: "UniqueCheck",
      helptext:
        "Corresponds to Blended, Face to Face + Virtual and Presencial + Virtual program.",
      options: [
        {
          title: "Blended",
        },
      ],
    },
    {
      key: "durationDataBlended",
      type: "MultiCheckSelect",
      title: "Blended Duration",
      placeholder: "Select a Duration",
      source: "DURATION",
    },
    {
      key: "locationDataBlended",
      type: "MultiCheckSelect",
      title: "lended Location",
      placeholder: "Select a Location",
      source: "LOCATION",
    },
  ],
  content: [
    {
      title: "Abstract",
      type: "RichText",
      html: true,
      key: "abstract",
      hideable: true,
      mandatory: true,
      validators: { maxChar: 240 },
      helptext: "Shown on the distributor card",
      humanReadable: true,
    },
    {
      key: "shortName",
      title: "Short name",
      type: "TextField",
      hideable: true,
      html: true,
      humanReadable: true,
      helptext: "This field will be used to link to events.",
    },
    {
      key: "weight",
      title: "Weight",
      type: "TextField",
      indexable: true,
      showList: true,
      helptext: "This field will be used to sort in distributors and lists.",
    },
    {
      title: "Date",
      type: "DateField",
      mandatory: true,
      key: "dateTime",
      helptext: "This date will be used to sort in distributors and lists.",
    },
    {
      key: "categories",
      title: "Select Categories",
      type: "MultiCheckSelectGroup",
      filled: true,
      note: "Go to the Category or Global Data sections to create as many as you need.",
      elements: [
        {
          key: "duration",
          placeholder: "Duration",
          source: "DURATION",
        },
        {
          key: "format",
          placeholder: "Format",
          source: "FORMAT",
        },
        {
          key: "intake",
          placeholder: "Intake",
          source: "INTAKE",
        },
        {
          key: "language",
          placeholder: "Language",
          source: "LANGUAGE",
        },
        {
          key: "location",
          placeholder: "Location",
          source: "LOCATION",
        },
        {
          key: "modeStudy",
          placeholder: "Mode of Study",
          source: "MODE_STUDY",
        },
        {
          key: "pathWaysData",
          placeholder: "Pathways",
          source: "PATHWAYS",
        },
        {
          key: "pathwaysExecutiveData",
          placeholder: "Pathways Executive Education",
          source: "PATHWAYS_EXECUTIVE",
        },
        {
          key: "programType",
          placeholder: "Program Type",
          source: "PROGRAM_TYPE",
        },
        {
          key: "schoolsData",
          placeholder: "Schools",
          source: "SCHOOLS",
        },
        {
          key: "units",
          placeholder: "Unit",
          source: "UNIT",
        },
        {
          key: "yearsExperience",
          placeholder: "Years of Experience",
          source: "YEARS_EXPERIENCE",
        },
        {
          key: "weightCategory",
          placeholder: "Weight Category",
          source: "WEIGHT_CATEGORY",
        },
      ],
    },
    {
      title: "Hero Section",
      type: "ComponentArray",
      maxItems: 1,
      whiteList: ["HeroProgram", "HeroProgramVideo"],
      key: "heroSection",
    },
    {
      title: "Call to Action Buttons",
      type: "FieldGroup",
      key: "descriptionButtons",
      collapsed: true,
      fields: [
        {
          title: "CTA 1",
          type: "ComponentContainer",
          whiteList: ["DescriptionButton"],
          key: "cta1",
          hideable: true,
        },
        {
          title: "CTA 2",
          type: "ComponentContainer",
          whiteList: ["DescriptionButton"],
          key: "cta2",
          hideable: true,
        },
      ],
    },
    {
      title: "Quick Facts Section",
      type: "ComponentArray",
      maxItems: 1,
      whiteList: ["ProgramQuickFacts"],
      key: "programQuickFactsSection",
    },
    {
      title: "Program Tabs Section",
      type: "ComponentArray",
      maxItems: 1,
      whiteList: ["ProgramTabs"],
      key: "programTabsSection",
    },
    {
      title: "Document Download",
      type: "ComponentArray",
      maxItems: 1,
      whiteList: ["DocumentDownload"],
      key: "documentDownloadSection",
    },
    {
      title: "Show pagination",
      type: "RadioGroup",
      key: "showPagination",
      mandatory: true,
      options: [
        { value: true, title: "Yes", name: "yes" },
        { value: false, title: "No", name: "no" },
      ],
    },
    {
      title: "Related Content",
      type: "ComponentArray",
      whiteList: allModulesWhitelist,
      key: "relatedContent",
    },
    {
      title: "Program comparator info",
      key: "programComparadorInfo",
      type: "FieldGroup",
      collapsed: true,
      helptext: "Shown on the Program Comparator",
      fields: [
        {
          key: "forPeopleWho",
          title: "For people who...",
          html: true,
          type: "RichText",
          humanReadable: true,
        },
        {
          key: "toBecome",
          title: "To become",
          type: "RichText",
          humanReadable: true,
        },
        {
          key: "reasonsWhy",
          title: "Reasons why",
          type: "RichText",
          humanReadable: true,
        },
        {
          key: "beyondTheProgram",
          title: "Beyond the program",
          type: "RichText",
          humanReadable: true,
        },
        {
          key: "residentialPeriods",
          title: "Residential periods",
          type: "RichText",
          humanReadable: true,
        },
      ],
    },
    {
      key: "imageFromHero",
      title: "Image from Hero",
      computed: page => {
        const imageObject =
          page?.template?.heroSection?.modules?.[0]?.image || {}
        return imageObject
      },
      type: "ImageField",
      hidden: true,
    },
    {
      key: "subtitleFromHero",
      title: "Subtitle from Hero",
      computed: page => {
        const subtitle =
          page?.template?.heroSection?.modules?.[0]?.subtitle || {}
        return subtitle
      },
      type: "TextField",
      hidden: true,
    },
    {
      key: "logosFromHero",
      title: "Logos from Hero",
      computed: page => {
        const logos = page?.template?.heroSection?.modules?.[0]?.logos || {}
        return logos
      },
      type: "ComponentArray",
      hidden: true,
    },
    {
      key: "quickFacts",
      title: "Quick Facts from Quick Facts Section",
      computed: page => {
        const quickFactsObject =
          page?.template?.programQuickFactsSection?.modules?.[0]?.elements || {}
        return quickFactsObject
      },
      type: "ComponentContainer",
      hidden: true,
    },
  ],

  default: {
    type: "template",
    templateType: "ProgramDetail",
    online: false,
    onsite: false,
    blended: false,
    abstract:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    shortName: null,
    weight: "9999",
    dateTime: "",
    data: {
      quantity: 1,
      mode: "navigation",
      order: "recent-asc",
    },
    heroSection: {
      component: "Section",
      name: "Hero Section",
      modules: [{ ...HeroProgram.default }],
      sectionPosition: 4,
    },
    programQuickFactsSection: {
      component: "Section",
      name: "Quick Facts Section",
      modules: [{ ...ProgramQuickFacts.default }],
      sectionPosition: 4,
    },
    programTabsSection: {
      component: "Section",
      name: "Program Tabs Section",
      modules: [{ ...ProgramTabs.default }],
      sectionPosition: 4,
    },
    cta1: {
      component: "DescriptionButton",
      style: "secondary",
      showLeftIcon: false,
      showRigthIcon: true,
      rightIcon: "aDownload",
      auxText: "Auxiliar text",
      link: {
        text: "Download Brochure",
        linkType: "url",
        url: {
          href: null,
          linkTo: null,
          linkToURL: null,
          newTab: true,
          noFollow: false,
        },
        modal: {
          requestInfo: {
            component: "RequestInfo",
          },
          floatingCTAMail: {
            component: "FloatingCTAMail",
          },
          floatingCTALightbox: {
            component: "FloatingCTALightbox",
          },
          floatingCTAVideo: {
            component: "FloatingCTAVideo",
          },
        },
      },
    },
    cta2: {
      component: "DescriptionButton",
      style: "primary",
      showLeftIcon: false,
      showRigthIcon: true,
      rightIcon: "higher",
      auxText: "Auxiliar text",
      link: {
        text: "Apply",
        linkType: "url",
        url: {
          href: null,
          linkToURL: null,
          linkTo: null,
          newTab: false,
          noFollow: false,
        },
        modal: {
          requestInfo: {
            component: "RequestInfo",
          },
          floatingCTAMail: {
            component: "FloatingCTAMail",
          },
          floatingCTALightbox: {
            component: "FloatingCTALightbox",
          },
          floatingCTAVideo: {
            component: "FloatingCTAVideo",
          },
        },
      },
    },
    documentDownloadSection: {
      component: "Section",
      name: "Document Download",
      modules: [],
      sectionPosition: 4,
    },
    showPagination: false,
    relatedContent: {
      component: "Section",
      name: "Related Content",
      modules: [{ ...SocialShare.default }],
      sectionPosition: 4,
    },
    residentialPeriods: "Lorem ipsum",
    forPeopleWho: "Lorem ipsum",
    beyondTheProgram: "Lorem ipsum",
    reasonsWhy: "Lorem ipsum",
    toBecome: "Lorem ipsum",
  },
  thumbnails: {
    "1x": ProgramDetail,
    "2x": ProgramDetail2x,
  },
}
