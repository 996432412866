import { getThemeOptions } from "@helpers/schemas"
import {
  SpecializationDistributor,
  SpecializationDistributor2x,
} from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Specialization Distributor",
  component: "SpecializationDistributor",
  category: "studies",
  dataPacks: ["SPECIALIZATIONS"],
  sectionList: {
    AcademicVideo: ["relatedContent"],
    ArticlesList: ["relatedContent"],
    AudiovisualList: ["relatedContent"],
    BasicTemplate: ["mainContent"],
    EpicAwardDetail: ["mainContent", "relatedContent"],
    EpicAwardList: ["relatedContent"],
    EventDetail: ["mainContent", "relatedContent"],
    EventList: ["relatedContent"],
    GeneralInfoDetail: ["gidMainContent", "gidRelatedContent"],
    GeneralInfoList: ["relatedContent"],
    Landing: ["mainContent"],
    NewsDetail: ["relatedContent"],
    NewsList: ["relatedContent"],
    ProgramDetail: ["relatedContent"],
    ProgramTabElement: ["componentModules"],
    ProjectDetail: ["mainContent", "relatedContent"],
    ProjectList: ["relatedContent"],
    PublicationDetail: ["relatedContent"],
    PublicationsList: ["relatedContent"],
    ScholarshipsList: ["relatedContent"],
    SearchResults: ["mainContent", "relatedContent"],
    SitemapTemplate: ["relatedContent"],
    StoriesList: ["relatedContent"],
    StoryDetail: ["mainContent", "relatedContent"],
    TeacherDetail: ["mainContent", "relatedContent"],
    TeachersList: ["relatedContent"],
  },
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Lorem ipsum" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          default:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
          humanReadable: true,
        },
        {
          title: "Specializations",
          type: "ReferenceField",
          sources: [{ structuredData: "SPECIALIZATIONS" }],
          key: "data",
          helptext: "This module will show maximum 20 elements",
        },
        {
          title: "No Results Text",
          type: "TextField",
          key: "noResultsText",
        },
        {
          title: "Content hierarchy for SEO",
          type: "Select",
          key: "cardTitleTag",
          options: [
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "Span" },
          ],
        },
        {
          title: "Cards titles link",
          type: "RadioGroup",
          key: "titlesLink",
          mandatory: false,
          options: [
            {
              value: true,
              title: "Yes",
              name: "yes",
            },
            {
              value: false,
              title: "No",
              name: "no",
            },
          ],
        },
        {
          title: "Link text",
          type: "TextField",
          key: "linkText",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Where should the link go?",
          type: "RadioGroup",
          key: "linkDirection",
          mandatory: false,
          helptext: "This only affects to automatic mode.",
          options: [
            {
              value: "pageOfCanonical",
              title: "Page of the canonical site",
              name: "pageOfCanonical",
            },
            {
              value: "pageOfSite",
              title: "Page of this site",
              name: "pageOfSite",
            },
          ],
        },
        {
          title: "Cards 'Apply' button",
          type: "RadioGroup",
          key: "applyButton",
          mandatory: true,
          options: [
            {
              name: "no",
              value: false,
              title: "No",
            },
            {
              name: "yes",
              value: true,
              title: "Yes",
            },
          ],
        },
        {
          title: "Grouping Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "groupingLink",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "SpecializationDistributor",
    anchorID: null,
    theme: "defaultAlt",
    verticalSpacing: "medium",
    title: { tag: "h2", content: "Lorem ipsum" },
    subtitle:
      "Nostrud aliquip occaecat do nisi id proident cupidatat eu cillum. Labore eiusmod cupidatat consectetur reprehenderit non esse labore occaecat sit.",
    detail:
      "Nostrud aliquip occaecat do nisi id proident cupidatat eu cillum. Labore eiusmod cupidatat consectetur reprehenderit non esse labore occaecat sit eu.",
    data: {
      mode: "auto",
      sources: [
        {
          structuredData: "SPECIALIZATIONS",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      order: "recent",
      quantity: 4,
      preferenceLanguage: false,
    },
    noResultsText: "Sorry, no results were found.",
    cardTitleTag: "h3",
    titlesLink: false,
    linkText: null,
    linkDirection: "pageOfSite",
    applyButton: false,
    groupingLink: {
      component: "Link",
      text: "Discover more specializations",
      style: "secondary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
  },
  thumbnails: {
    "1x": SpecializationDistributor,
    "2x": SpecializationDistributor2x,
  },
}
