import { getThemeOptions } from "@helpers/schemas"
import { Footer, Footer2x } from "@thumbnails/Modules"

const whiteList = [
  "CardCollection",
  "CTACollection",
  "FeaturedBlock",
  "FeedbackForm",
  "Principles",
  "SocialMedia",
  "Spacer",
  "Testimonial",
  "VideoCollection",
]

export default {
  schemaType: "module",
  displayName: "Footer",
  component: "Footer",
  type: "footer",
  category: "footer",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Name",
          type: "TextField",
          key: "title",
          mandatory: true,
          humanReadable: true,
        },

        {
          title: "Show Web Links",
          type: "RadioGroup",
          key: "showWebLinks",
          mandatory: true,
          options: [
            {
              value: true,
              title: "Yes",
              name: "yes",
            },
            {
              value: false,
              title: "No",
              name: "no",
            },
          ],
        },

        {
          title: "Title",
          type: "TextField",
          key: "claim",
          mandatory: true,
          humanReadable: true,
        },

        {
          title: "Add button",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link",
          hideable: true,
        },

        {
          title: "Order social media icons",
          type: "ConditionalField",
          key: "orderSocialIcons",
          options: [
            { value: true, title: "Yes", name: "yes" },
            { value: false, title: "No", name: "no" },
          ],
          fields: [
            {
              title: "Select social media order",
              type: "CheckGroup",
              key: "socialMediaOrder",
              condition: true,
              options: [
                {
                  value: "instagram",
                  title: "Instagram",
                  name: "instagram",
                },
                {
                  value: "linkedIn",
                  title: "LinkedIn",
                  name: "linkedIn",
                },
                {
                  value: "twitter",
                  title: "X/Twitter",
                  name: "twitter",
                },
                {
                  value: "facebook",
                  title: "Facebook",
                  name: "facebook",
                },
                {
                  value: "tiktok",
                  title: "TikTok",
                  name: "tiktok",
                },
                {
                  value: "youtube",
                  title: "YouTube",
                  name: "youtube",
                },
                {
                  value: "podcast",
                  title: "Podcast",
                  name: "podcast",
                },
                {
                  value: "flickr",
                  title: "Flickr",
                  name: "flickr",
                },
                {
                  value: "snapchat",
                  title: "Snapchat",
                  name: "snapchat",
                },
                {
                  value: "email",
                  title: "Newsletter",
                  name: "email",
                },
              ],
            },
          ],
        },

        {
          title: "Copyright Text",
          type: "TextField",
          key: "copyrightText",
          hideable: true,
          humanReadable: true,
        },

        {
          title: "Logo",
          type: "Select",
          key: "school",
          options: [
            { value: "IE", label: "Instituto de empresa" },
            { value: "IENYC", label: "Instituto de empresa NYC" },
            { value: "SUMMERSCHOOL", label: "Summer school" },
            { value: "BUS", label: "Business School" },
            {
              value: "CLIC",
              label: "Center for Corporate Learning and Talent Management",
            },
            { value: "XL", label: "Lifelong Learning" },
            { value: "LAW", label: "Law School" },
            { value: "ARQ", label: "School of Architecture and Design" },
            { value: "GPA", label: "School of Global and public Affairs" },
            {
              value: "SPEGA",
              label: "School of Politics, Economics & Global Affairs",
            },
            { value: "HMN", label: "Humanities School" },
            { value: "HST", label: "School of Science and Technology" },
            { value: "AL", label: "Alumni" },
            { value: "CR", label: "Corporate Relations" },
            { value: "CGC", label: "Center for the Gobernance of Change" },
            { value: "GPC", label: "Global Policy Center" },
            { value: "ENT", label: "Entrepreneurship" },
            { value: "UNI", label: "University" },
            { value: "TC", label: "Talent & Career" },
            { value: "FOUND", label: "IE Foundation" },
            { value: "FAID", label: "Financial Aid" },
            {
              value: "LEAD",
              label: "Center Middle East",
            },
            { value: "SST50", label: "SST 50" },
            { value: "Architecture50", label: "Architecture 50" },
            { value: "Business50", label: "Business 50" },
            { value: "Law50", label: "Law 50" },
            { value: "Spega50", label: "SPEGA 50" },
            { value: "University50", label: "University 50" },
            { value: "LifelongLearning50", label: "LifelongLearning 50" },
            { value: "Humanities50", label: "Humanities 50" },
          ],
        },

        {
          title: "Other modules above the footer",
          type: "ComponentArray",
          maxItems: null,
          whiteList: whiteList,
          key: "elements",
          mandatory: false,
          contentType: "modules",
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          type: "UniqueCheck",
          key: "setAsDefault",
          options: [
            {
              title: "Set as Footer default",
            },
          ],
        },
        {
          title: "Select Footer Menu",
          key: "legalMenu",
          type: "AsyncSelect",
          entity: "menu_containers",
          mandatory: true,
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "accent",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "Footer",
    type: "footer",

    // Content
    title: "Footer name",
    showWebLinks: true,
    claim: "Lorem Ipsum",
    link: { component: "Link" },
    orderSocialIcons: false,
    socialMediaOrder: [],
    copyrightText: null,
    school: "IE",
    elements: [],

    // Config
    setAsDefault: false,
    footerMenu: null,
    legalMenu: null,
    theme: "accent",
  },
  thumbnails: {
    "1x": Footer,
    "2x": Footer2x,
  },
}
