import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import { HeroImage, HeroImage2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Hero Specialization",
  component: "HeroSpecialization",
  category: "hero",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Subtitle",
          type: "TextField",
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "HeroSpecialization",
    title: { content: "Title", tag: "h1" },
    subtitle: "Lorem Ipsum",
  },
  thumbnails: {
    "1x": HeroImage,
    "2x": HeroImage2x,
  },
}
